@media (prefers-reduced-motion: no-preference) {
	.pieChart path {
		animation: circle-animation infinite 1s linear;
		opacity: 0.5;
	}
}

@keyframes circle-animation {
	0%,
	25% {
		opacity: 1;
	}

	25%,
	100% {
		opacity: 0.5;
	}
}

.pieChart path:nth-child(1) {
	animation-delay: 0s;
}
.pieChart path:nth-child(2) {
	animation-delay: 0.2s;
}
.pieChart path:nth-child(3) {
	animation-delay: 0.4s;
}
.pieChart path:nth-child(4) {
	animation-delay: 0.6s;
}
.pieChart path:nth-child(5) {
	animation-delay: 0.8s;
}
