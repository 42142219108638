@import 'normalize.css/opinionated.css';
@import url('./styleSettings/variables.css');
@import url('./styleSettings/fonts.css');

.full-height {
	height: 100%;
	overflow: hidden;
}

body,
input,
select,
textarea,
button {
	font-family: var(--ring-font-family);
	font-size: var(--ring-font-size);
}

body {
	font-size: var(--ring-font-size);
	font-weight: 400;
	line-height: 20px;

	transition: color 0.2s, background 0.2s;
	color: var(--qd-color-text-1);
	background: var(--qd-color-main-bg);
}

code {
	font-family: var(--qd-code-font-family-regular);
}
