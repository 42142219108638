.container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.loader {
	margin-top: calc(var(--ring-unit) * -11);
	margin-bottom: calc(var(--ring-unit) * 2);
}

.message {
	font-size: var(--ring-font-size);
	line-height: var(--ring-line-height);
	text-align: center;
}
