@font-face {
	font-family: 'Poppins Bold';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Bold'), url('../media/fonts/poppins-webfont/Poppins-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Poppins Regular';
	font-style: normal;
	src: local('Poppins Regular'),
		url('../media/fonts/poppins-webfont/Poppins-Regular.woff') format('woff');
}

@font-face {
	font-family: JetBrains Mono Bold;
	font-style: normal;
	font-weight: normal;
	src: local('JetBrains Mono Bold'),
		url('../media/fonts/jetbrains-mono-webfont/JetBrainsMono-Bold.woff') format('woff');
}

@font-face {
	font-family: JetBrains Mono Regular;
	font-style: normal;
	src: local('JetBrains Mono Regular'),
		url('../media/fonts/jetbrains-mono-webfont/JetBrainsMono-Regular.woff') format('woff');
}
