:root,
.ring-ui-theme-dark {
	--qd-card-hover-color: var(--ring-sidebar-background-color);
}

:root {
	--qd-main-title-font-family-bold: 'Poppins Bold', system-ui, -apple-system, BlinkMacSystemFont,
		Segoe UI, Roboto, Oxygen, Cantarell, Droid Sans, Helvetica Neue, Arial, sans-serif;

	--qd-card-min-width: 320px;
	--qd-card-height: 184px;
	--qd-card-solid-background-color-rgb: var(--ring-content-background-components);
	--qd-card-solid-background-color: rgb(var(--qd-card-solid-background-color-rgb));
	--qd-card-shadow-s: 0 0 1.5px 0 rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.05);

	--qd-color-severity-critical: #ff7981;
	--qd-color-severity-high: #fdb55b;
	--qd-color-severity-moderate: #ffda4d;
	--qd-color-severity-low: #c6c2d5;
	--qd-color-severity-info: #6ab7f6;
	--qd-color-severity-success: #97d15d;
}

.ring-ui-theme-dark {
	--qd-card-solid-background-color-rgb: 48, 51, 54;
	--qd-card-solid-background-color: rgb(var(--qd-card-solid-background-color-rgb));
}

@media only screen and (max-width: 450px) {
	:root {
		--qd-card-min-width: 290px;
	}
}

@media only screen and (max-width: 375px) {
	:root {
		--qd-card-min-width: 260px;
		--qd-card-height: 200px;
	}
}

@media (min-width: 2540px) {
	:root {
		--qd-card-min-width: calc(320px * var(--scale-factor));
		--qd-card-height: calc(184px * var(--scale-factor));
	}
}
